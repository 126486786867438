import React, { FC } from 'react';

import './Copyright.scss';
import { useStaticQuery, graphql } from 'gatsby';
import { CopyrightProps, FooterType } from './models';

const Copyright: FC<CopyrightProps> = ({ langProps }) => {
  const {
    allFooter: { nodes },
  }: FooterType = useStaticQuery(graphql`
    {
      allFooter {
        nodes {
          lang
          copyright {
            properties {
              text
            }
          }
        }
      }
    }
  `);

  const {
    copyright: { properties },
  } = nodes.filter(({ lang }) => lang === langProps.lang)[0];

  return <div className="copyright">{properties.text}</div>;
};

export default Copyright;
