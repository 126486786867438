import React, { FC, useState } from 'react';
import { toBoolean } from 'gatsby-theme-husky/src/utils/stringUtils/stringUtils';
import { HeaderProps } from 'gatsby-theme-husky/src/components/Header/models';
import HeaderNav from './HeaderNav';
import HeaderTop from './HeaderTop';

import './HeaderOverride.scss';

const Header: FC<HeaderProps> = ({
  isCustomLogo,
  brandSettings,
  socialMedia,
  langProps,
  colorExtraClass,
  searchResultsPage,
  isHeaderTopVisible,
}) => {
  const [isMobileNavOpened, setMobileNavOpened] = useState(false);

  const headerNavProps = {
    isCustomLogo,
    isMobileNavOpened,
    setMobileNavOpened,
    brandSettings,
    socialMedia,
    langProps,
    searchResultsPage,
    colorExtraClass,
  };

  const headerTopProps = {
    socialMedia,
    langProps,
    colorExtraClass,
  };

  return (
    <header className={`main-header ${colorExtraClass}`}>
      {toBoolean(isHeaderTopVisible) ? <HeaderTop {...headerTopProps} /> : null}
      <HeaderNav {...headerNavProps} />
    </header>
  );
};

export default Header;
