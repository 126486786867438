import './FooterOverride.scss';
import React, { FC } from 'react';

import FooterNav from 'components/Footer/FooterNav';
import FooterLinks from 'components/Footer/FooterLinks';
import Copyright from 'components/Footer/Copyright';
import { FooterProps } from './models';

const Footer: FC<FooterProps> = ({
  brandSettings,
  socialMedia,
  langProps,
  isCustomLogo,
  colorExtraClass,
}) => {
  const footerNavProps = {
    isCustomLogo,
    brandSettings,
    socialMedia,
    langProps,
  };

  return (
    <footer className={`${colorExtraClass || ''}`} data-testid="footer">
      <FooterNav {...footerNavProps} />
      <FooterLinks {...{ langProps }} />
      <Copyright {...{ langProps }} />
    </footer>
  );
};

export default Footer;
