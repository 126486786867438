import React, { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { useScreenRecognition } from 'utils/hooks';
import ScreenRecognitionContext from 'utils/context/screenRecognitionContext';
import Breadcrumbs from 'common/Breadcrumbs';
import Header from 'components/Header';
import VideoHeader from 'components/VideoHeader';
import Footer from '../../components/Footer';
import { LayoutProps, StaticPropsType } from './models';

const Layout: FC<LayoutProps> = ({
  children,
  headerTransparent,
  className,
  langProps,
  breadcrumbs,
  isVideoHeader,
  isWithoutFooter,
  colorExtraClass,
  veetMeenHeader,
  isVeetMen,
}) => {
  const CSSclassNames = classNames('layout', className, {
    'layout--transparent': headerTransparent,
  });

  const screenResolution = useScreenRecognition();
  const screenResolutionProviderValues = useMemo(() => ({ screenResolution }), [screenResolution]);

  const {
    allBrandSettings,
    socialMedia,
    allUmbracoSearchResults: searchResultsPage,
    header: { isHeaderTopVisible },
  }: StaticPropsType = useStaticQuery(graphql`
    {
      allUmbracoSearchResults {
        nodes {
          url
          langProps {
            lang
          }
        }
      }
      allBrandSettings {
        nodes {
          brandName
          url
          lang
          brandLogo {
            ...umbracoImage
          }
          customLogoRegex
          customBrandLogo {
            ...umbracoImage
          }
          videoTemplateBrandLogo {
            ...umbracoImage
          }
          videoTemplateBrandLogoBackground {
            ...umbracoImage
          }
        }
      }
      header {
        isHeaderTopVisible
      }
      socialMedia {
        socialMediaItems {
          properties {
            socialMediaIcon
            socialMediaAriaLabel
            isVisible
            socialMediaIconColor {
              label
              value
            }
            socialMediaHoverIconColor {
              label
              value
            }
            socialMediaLink {
              name
              target
              url
              icon
              published
            }
          }
        }
      }
    }
  `);

  const brandSettings = allBrandSettings.nodes.filter(({ lang }) => lang === langProps.lang)[0];
  const videoBrandLogo = brandSettings.videoTemplateBrandLogo;
  const videoBrandLogoBackground = brandSettings.videoTemplateBrandLogoBackground;
  const customRegex = new RegExp(allBrandSettings.nodes[0].customLogoRegex);

  const [isCustomLogo, setCustomLogo] = useState(false);

  useEffect(() => {
    const location = window.location.pathname;
    setCustomLogo(customRegex.test(location));
    if (isCustomLogo) {
      const layout = document.querySelector('.layout');
      layout?.classList.add('custom-variant');
    }
  }, [customRegex]);
  const brandSettingsMen = veetMeenHeader?.brandSettings.nodes.filter(
    ({ lang }) => lang === langProps.lang
  )[0];

  const selectedBrandSettings = isVeetMen ? brandSettingsMen : brandSettings;

  return (
    <ScreenRecognitionContext.Provider value={screenResolutionProviderValues.screenResolution}>
      <div className={CSSclassNames}>
        {!isVideoHeader ? (
          <Header
            {...{ colorExtraClass }}
            isCustomLogo={isCustomLogo}
            brandSettings={selectedBrandSettings}
            socialMedia={socialMedia}
            searchResultsPage={searchResultsPage}
            langProps={langProps}
            isHeaderTopVisible={isHeaderTopVisible}
          />
        ) : (
          <VideoHeader
            brandLogo={videoBrandLogo}
            brandLogoBackground={videoBrandLogoBackground}
            brandName={brandSettings.brandName}
            homepageLink={brandSettings.url}
          />
        )}
        <main id="main" className="layout__main">
          {breadcrumbs?.breadCrumb?.length ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}
          {children}
        </main>
        {!isWithoutFooter ? (
          <Footer
            {...{ colorExtraClass }}
            isCustomLogo={isCustomLogo}
            brandSettings={selectedBrandSettings}
            socialMedia={socialMedia}
            langProps={langProps}
          />
        ) : null}
      </div>
    </ScreenRecognitionContext.Provider>
  );
};

export default Layout;
